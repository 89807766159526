<template lang="">
  <div class="card-grid">
    <ChartCard chartType="pie" questionType="gender" />
    <ChartCard chartType="bar" questionType="age" />
    <ChartCard chartType="pie" questionType="job" />
  </div>
</template>
<script>
import ChartCard from "../chart-cards/ChartCard.vue";
export default {
  name: "BackgroundInfo",
  metaInfo() {
    return {
      title: this.$t("dashboard.nav.background_info"),
    };
  },
  components: { ChartCard },
};
</script>
